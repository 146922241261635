import React, { useEffect } from 'react';
import './App.css';
import '@aws-amplify/ui-react/styles.css';
import { Route, Routes } from 'react-router-dom';
import { LandingPage } from './components/LandingPage/LandingPage';
import NavigationBar from './components/NavigationBar/NavigationBar';

function App() {

  useEffect(() => {

  }, []);

  return (
    <div className="App">
      <NavigationBar/>
      <Routes>
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </div>
  );
}

export default App;
