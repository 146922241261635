import { useRef } from 'react';
import { Button, Box } from '@mui/material';
import ContactSection from './ContactSection';
import FeaturesSection from './FeaturesPanel';
import OverviewSection from './OverviewSection';

export function LandingPage() {
    const overviewRef = useRef(null);
    const featuresRef = useRef(null);
    const contactRef = useRef(null);

    const scrollToRef = (ref: any) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <Box sx={{ backgroundColor: '#3c6ad9' }}>
            <div ref={overviewRef}>
                <OverviewSection />
                {/* <Button onClick={() => scrollToRef(featuresRef)}>Learn more</Button> */}
            </div>
            <div ref={featuresRef}>
                <FeaturesSection />
                {/* <Button onClick={() => scrollToRef(contactRef)}>Get in touch</Button> */}
            </div>
            <div ref={contactRef}>
                <ContactSection />
            </div>
        </Box>
    );
}