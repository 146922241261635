/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://n53n5kc44rfv5klvkkol7p6a7y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5gtv2uqq4rhfxo6b7acw4uzzxe",
    "aws_cognito_identity_pool_id": "us-east-1:b56cc085-70f4-4ed5-b3cb-b267c57c7922",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Vy69jL3rE",
    "aws_user_pools_web_client_id": "3jknf81slteov3eg6h9i5foh54",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "maps": {
                "items": {
                    "mapefb647dd-trudy": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "mapefb647dd-trudy"
            },
            "search_indices": {
                "items": [
                    "placeindex8a261a2d-trudy"
                ],
                "default": "placeindex8a261a2d-trudy"
            }
        }
    }
};


export default awsmobile;
