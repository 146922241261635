import {
    Button, Menu, MenuItem,
    AppBar, Toolbar, Hidden,
    Box, IconButton
} from "@mui/material";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import './NavigationBar.css'
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, Link } from 'react-router-dom';
import { Auth } from "aws-amplify";

export default function NavigationBar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const { user } = useAuthenticator();

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleMobileMenuClick = (link: string) => {
        setAnchorEl(null);
        navigate(link)
    };

    const handleLogout = async (event: React.MouseEvent) => {
        // Solution source: https://github.com/aws-amplify/amplify-js/issues/10198
        event.preventDefault();
        setTimeout(async () => {
            try {
                await Auth.signOut();
                window.location.href = "/";
            } catch (error) {
                console.log("error signing out: ", error);
                event.preventDefault();
            }
        });
    };

    const handleLogin = () => {
        navigate('/');
    };

    return (
        <AppBar position="fixed">
            <Toolbar sx={{ backgroundColor: 'white', height: '64px' }}>
                <Hidden mdDown>
                    <Box sx={{ display: 'flex', flexGrow: 1 }}>
                        <Button component={Link} to="/" sx={{ mr: 2 }}>Home</Button>
                        <Button component={Link} to="/" sx={{ mr: 2 }}>Dashboard</Button>
                    </Box>
                </Hidden>
                <Hidden lgUp>
                    <IconButton edge="start" aria-label="menu" sx={{ mr: 2 }} onClick={handleClick}>
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => handleMobileMenuClick('/')}>Home</MenuItem>
                        <MenuItem onClick={() => handleMobileMenuClick('/')}>Dashboard</MenuItem>
                    </Menu>
                </Hidden>
                <img src="WayfareBuddyNavbarLogo.svg" alt="WayfareBuddy Logo" className={'toolbarLogo'} />
                <Box sx={{ display: 'flex', flexGrow: '1', justifyContent: 'right' }}>
                    {!user && <Button onClick={handleLogin}>Sign In</Button>}
                    {user && <Button onClick={handleLogout}>Sign Out</Button>}
                </Box>
            </Toolbar>
        </AppBar>
    );
}
