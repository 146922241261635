import { Box, Typography, Button, TextField, AlertProps, Snackbar } from '@mui/material';
import emailjs from '@emailjs/browser';
import { useState } from "react";
import MuiAlert from '@mui/material/Alert';
import React from 'react';

const SERVICE_ID = 'service_h1wmqy7'
const TEMPLATE_ID = 'template_q4rg3mh'
const PUBLIC_KEY = 'O2obYEkULsTZu8Xem'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function ContactSection() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [operationSuccess, setOperationSuccess] = useState(false);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlertOpen(false);
    };

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const templateParams = {
            name,
            email,
            message,
        };

        emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, PUBLIC_KEY)
            .then((result) => {
                console.log(result.text);
                setOperationSuccess(true);
                setAlertOpen(true);
            }, (error) => {
                console.log(error.text);
                setOperationSuccess(false);
                setAlertOpen(true);
            });

        // Clear the form fields
        setName('');
        setEmail('');
        setMessage('');
    }

    return (
        <section id='contact'>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={operationSuccess ? "success" : "error"} sx={{ width: '100%' }}>
                    {operationSuccess ? "Message delivered" : "Unable to deliver message. Try again later."}
                </Alert>
            </Snackbar>
            <Box sx={{ bgcolor: 'primary.main', py: 8, color: '#fff' }}>
                <Box sx={{ maxWidth: 720, mx: 'auto', px: 4 }}>
                    <Typography variant="h4" align="center" gutterBottom>Contact</Typography>
                    <Typography variant="body1" align="center" sx={{ mb: 6 }}>
                        This app is under active development.
                        Have a feature suggestion? Send us a message and we will
                        respond back as soon as possible.
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <TextField label="Name" variant="filled" sx={{ mb: 3, width: '100%', maxWidth: 500 }} value={name} onChange={(event) => setName(event.target.value)} />
                        <TextField label="Email" variant="filled" sx={{ mb: 3, width: '100%', maxWidth: 500 }} value={email} onChange={(event) => setEmail(event.target.value)} />
                        <TextField label="Message" multiline rows={4} variant="filled" sx={{ mb: 3, width: '100%', maxWidth: 500 }} value={message} onChange={(event) => setMessage(event.target.value)} />
                        <Button color="secondary" type="submit" variant="contained" sx={{ width: '100%', maxWidth: 500 }}>Send Message</Button>
                    </Box>
                </Box>
            </Box>
        </section>
    );
}
