import { createTheme, Theme } from "@mui/material/styles";

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: "#3c6ad9",
    },
    secondary: {
      main: "#e3289d"
    }
  }
});
export default theme;