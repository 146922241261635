import {  Typography, Box, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

function Feature(props: { icon: JSX.Element; title: string; description: string; }) {
    return (
        <ListItem>
            <ListItemIcon>{props.icon}</ListItemIcon>
            <ListItemText primary={props.title} secondary={props.description} />
        </ListItem>
    );
}

export default function FeaturesSection() {
    return (
        <Box sx={{ bgcolor: '#fff', py: 8 }}>
            <Box sx={{ maxWidth: 720, mx: 'auto', px: 4 }}>
                <Typography variant="h4" align="center" gutterBottom>About</Typography>
                <Typography variant="body1" align="center" sx={{ mb: 6 }}>
                    WayfareBuddy - your ultimate travel companion!
                    Plan your perfect trip itinerary, track your budget,
                    store important documents, and get personalized
                    AI-powered recommendations based on your likes
                    and interests. Say goodbye to travel planning
                    stress and hello to hassle-free exploration with
                    WayfareBuddy!</Typography>
                <List sx={{ bgcolor: '#f5f5f5', borderRadius: 1, mb: 6 }}>
                    <Feature icon={<CheckIcon />} 
                        title="Trip Itinerary Planning" 
                        description="Use WayfareBuddy to create a detailed itinerary for your trip with just a few clicks. Choose your destination, set your travel dates, and add your desired accommodation, activity, and restaurant plans. With the ability to add multiple options for each time block, you can compare and choose the best plan for you, and mark the others as tentative. Customization is the name of the game with WayfareBuddy's itinerary planning feature." />
                    <Feature icon={<CheckIcon />} 
                        title="Budget Tracking" 
                        description="Stay on top of your travel expenses with WayfareBuddy's budget tracking feature. Set your budget for the trip and easily track your expenses in real-time. WayfareBuddy will help you stick to your budget and avoid overspending." />
                    <Feature icon={<CheckIcon />} 
                        title="Document Storage" 
                        description="Keep all your important travel documents in one place with WayfareBuddy's document storage feature. Store your passport, visa, travel insurance, and other important documents securely in the app, and access them anytime, anywhere." />
                    <Feature icon={<CheckIcon />} 
                        title="AI-Powered Recommendations" 
                        description="Let WayfareBuddy help you plan your trip activities by suggesting attractions based on your likes, interests, and preferences. And now, with our ChatGPT-powered chatbot, you can ask specific questions to get personalized recommendations quickly and easily. Whether you're looking for the best museums in town or the most scenic hiking trails, ChatGPT has got you covered." />
                    <Feature icon={<CheckIcon />} 
                        title="Custom NFTs" 
                        description="Turn your trip into a unique and memorable experience with WayfareBuddy's NFT generation feature. Generate one-of-a-kind NFTs (Non-Fungible Tokens) that document and commemorate each part of your trip. From the places you visited to the experiences you had, each NFT represents a special moment of your journey. Share them with friends and family, or keep them as a personal keepsake. With NFT generation, your trip memories will last a lifetime." />
                    
                </List>
                <Typography variant="body1" align="center">
                    With WayfareBuddy, you can enjoy your travel experience to 
                    the fullest, knowing that you have all your trip details 
                    organized and under control.
                </Typography>
            </Box>
        </Box>
    );
}