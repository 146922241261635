import './OverviewSection.css'

export default function OverviewSection() {

    return (
        <section id="overview" className={'overviewSection'}>
            <div className={'overviewText'}>
                <h1>Wayfare Buddy</h1>
                <h2>If you dream it, we'll help you plan it.</h2>
            </div>
        </section>
    );
}